@import '../../../../../../variables';

$card-border-radius: 15px;
$dark-color: #1e2021;

$animation-duration: .2s;

@mixin border-radius($radius) {
	border-radius: $radius;

	@media (max-width: 767px) {
		border-radius: $radius / 3;
	}
}

.card-container, .card, .card .side {
	width: 100%;
	height: 100%;
	display: block;
}
.card-container {
	cursor: pointer;
	perspective: 600px;
}
.card {
	@include border-radius($card-border-radius);
	position: relative;
	transition: transform .8s, box-shadow $animation-duration, border-color $animation-duration;
	transform-style: preserve-3d;
	box-shadow: 0 0 3px rgba(black, .15);
	//border: 2px solid #bababa;
	padding-bottom: 100%;

	.side {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		@include border-radius($card-border-radius - 2);
	}
	.front {
		background-size: cover;
		background-position: center;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.back {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		transform: rotateY(180deg);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.card-container:hover .card {
	//border: 2px solid $color-main;
	box-shadow: 0 0 10px rgba($color-main, .6);

	.back::after {
		opacity: .5;
	}
}
.card-container:hover .card.flipped {
	//border: 2px solid darken($color-main, 5%);
}

.card.flipped {
	//border: 2px solid darken($color-main, 10%);
	transform: rotateY(180deg);
}
