//Colors
$color-white: #ffffff;
$color-bg: #e6e6e6;
$color-main: #ed1c24;
$color-text: #3f3f40;
$color-error: #dc3545;
$color-valid: #28a745;
$color-correct: #0be038;
$color-incorrect: #c31919;

//Fonts
$font-body: 'Ooredoo', sans-serif;
