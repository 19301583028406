@import '../../../variables';

.auth-component {
	margin-left: 20px;
	margin-right: 20px;

	.prize {
		height: 230px;
		text-align: center;
		background-image: url("../../../resources/img/logo-256.png");
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin-bottom: 10px;

		img {
		}
	}

	&.prefix-active {
		input:not([type="submit"]) {
			padding-left: 56px;
		}
	}

	.inputs-wrapper {
		min-height: 88px;
	}

	.shadow-wrapper {
		box-shadow: 0 3px 10px rgba(0,0,0,.5);
	}

	.form-field {
		&:nth-child(2) {
			margin-top: -2px;
			&::after {
				content: '';
				position: absolute;
				left: 15px;
				right: 15px;
				top: 0;
				max-width: 100%;
				height: 1px;
				background-color: $color-bg;
			}
		}
	}

	form {

		button[type="submit"] {
			appearance: none;
			width: 100%;
			border: none;
			margin-top: 10px;
			padding: 0;

			&:focus {
				outline: 0;
			}
		}
	}

	input:not([type="submit"]) {
		background-repeat: no-repeat;
		background-position: 15px center;
		padding-left: 42px;
	}

	input[type="tel"] {
		background-image: url('./img/icon-mobile.png');
	}

	input[type="password"] {
		background-image: url('./img/icon-lock.png');
	}

	.btn-actions {
		display: flex;
		justify-content: space-between;

		a {
			flex: 1;
			margin-top: 10px;

			&:nth-child(2) {
				margin-left: 10px;
			}

			.btn {
				width: 100%;
			}
		}
	}
}

@media (max-height: 574px) {
	.auth-component {
		.prize {
			height: 190px;
		}

		.inputs-wrapper {
			min-height: 70px;
		}

		form {

			.button[type="submit"] {
				margin-top: 8px;
			}
		}

		.btn-actions {

			a {
				margin-top: 8px;
			}
		}
	}
}
