@import "../../variables.scss";

.text-pages {
    background-color: $color-white;
    min-height: 100%;
    padding: 24px 20px;

    p {
        margin: 16px 0;
        line-height: 20px;

        &:first-of-type {
            margin-top: 20px;
        }
    }
}

