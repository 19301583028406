@import "../../variables.scss";

.leaderboard-component {
	color: $color-text;
	font-size: 16px;
	padding: 0 18px 18px;

	h2 {
		text-align: center;
		margin-bottom: 20px;
	}

	.top-three {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
		padding-bottom: 15px;

		& > .top-three-single {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&:first-child {
				width: 100%;
			}
		}

		.score {
			font-weight: 700;
		}

		.name {
			display: block;
			height: 20px;
		}

		.avatar {
			margin-left: auto;
			margin-right: auto;

			img {
				padding: 3px;
			}
		}
	}

	.users-listing {
		margin: auto;

		.users-single {
			> td {
				border-bottom: 1px solid $color-text;

				padding: 5px 2px;
			}

			.rank {
				width: 10%;
				text-align: center;
			}

			.nickname {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.avatar {
					margin-right: 10px;

					img {
						padding: 2px;
					}
				}
			}

			.score {
				width: 10%;
				text-align: center;
			}

			&:last-child {
				> td {
					border-bottom: 2px solid $color-text;
				}
			}
		}
	}
}
