@import '../../../../variables';

header {
	height: 55px;

	.nav-control {
		position: relative;
		z-index: 201;
		display: flex;
		align-items: center;
		padding-left: 10px;
		padding-top: 19px;

		.btn-nav {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 25px;

			img {
				display: block;
				height: 16px;
			}
		}

		.logo-header {
			max-width: 85px;
			max-height: 15px;
		}
	}
}
