@import '../../variables';

.btn {
	display: inline-block;
	background-color: $color-main;
	font-size: 18px;
	font-weight: 500;
	height: 50px;
	line-height: 50px;
	color: $color-white;
	text-align: center;
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	border: 0;

	&.full-width {
		width: 100%;
	}

	&.box-shadow {
		box-shadow: 0 3px 10px rgba(0,0,0,.5);
	}

	&.outline {
		background-color: transparent;
		color: $color-main;
		border: 1px solid $color-main;
		font-size: 12px;
		height: 38px;
		line-height: 38px;
	}
}

@media (max-height: 574px) {
	.btn {
		height: 40px;
		line-height: 40px;
		font-size: 16px;

		&.outline {
			height: 31px;
			line-height: 31px;
		}
	}
}
